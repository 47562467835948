<template>
  <v-dialog v-model="show" max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly"
transition="dialog-bottom-transition" persistent>   
    <v-card>
        <BasicToolbar :title="title" @close="close" tabindex="4"/>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-alert v-if="error" type="error" dismissible>{{ error }}</v-alert>
                <v-text-field v-model="teamClone.teamName" type="text" label="Team" :rules="teamRules" required autofocus></v-text-field>
            </v-form>
        </v-card-text>
        
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!valid" @click="submit">Opslaan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicToolbar from "@/components/basic/BasicToolbar";

export default {
  name: "UpdateTeamDialog",
  components: {
    BasicToolbar
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
        valid: false,
        show: true,
        error: null,
        title: "Team wijzigen",
        teamRules: [v => !!v || 'Team moet ingegeven worden.'],
        teamClone: JSON.parse(JSON.stringify(this.item)),
    }
  },
  methods: {
    close() {
      this.show = false
      this.$emit('close')
      this.reset()
    },
    reset() {
      this.$refs.form.reset()
    },
    submit() {
      this.$store.dispatch('teamsModule/updateTeam', this.teamClone).then(() => this.close())
    }
  },
  computed: {
    getOrganizationId() {
      return this.$store.state.authModule.user.organizationId
    }
  }
}
</script>